import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/2023/5D/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/2023/5D/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/2023/5D/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/2023/5D/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/2023/5D/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/2023/5D/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/2023/5D/7.jpg';
// import p8 from 'assests/Photos/ClassAssembly/2023/5D/8.jpg';


// import p7 from 'assests/Photos/ClassAssembly/6D/7.jpg';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly5D2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/5D/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/5D/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/5D/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/5D/4.webp`;
const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/5D/5.webp`;
const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/5D/6.webp`;
const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/5D/7.webp`;
const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/class-assembly/5D/8.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },

      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },

    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      ‘Beat Plastic Pollution ’
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 5D  Date: 14.06.2023 – 16.06.2023

        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of Class 5D presented their assembly on the topic, ‘Beat Plastic Pollution’ from Wednesday, 14 June 2023 
        to Friday, 16 June 2023. The topic was introduced through a small skit on the present condition of Earth due to 
        pollution caused by plastic everywhere. A brief insight into the topic was shared by the students. The measures to 
        reduce plastic pollution were showcased through a song and dance in a creative way, integrating Art with learning. 
        Students also presented and explained inspirational thoughts on the topic.

 <br></br>
 On the occasion of ‘World Environment Day’, a Teacher talk was presented by Ms Swarupa Javalkar. A short story was 
 narrated about, how small actions could bring in the desired change. Students also pledged to reduce and refuse the 
 usage of single use plastics. They were made to repeat the words ‘My Environment My Responsibility’. 
        <br></br>
        The assembly was well appreciated for its content and presentation.
        
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “A LITTLE EFFORT IS BETTER THAN NO EFFORT”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly5D2023;